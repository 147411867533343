<app-header></app-header>


<div class="main-banner">
  <div class="banner-image">
    <div class="banner-text">
      <div class="container">
        <h1 class="careers-heading">Careers at
          <br />
          Spruce
        </h1>
        <div class="view-button">
          <a href="https://sprucepowe1stg.wpengine.com/job-openings/">View Openings</a>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="select-option">
  <div class="container">
    <div class="row pt-5 pb-5">
      <div class="col-sm-12 col-lg-4 ps-sm-2 ps-lg-4 details-card1">
        <div class="text-center">
          <img src="/assets/thum.png" alt="benefits image" />
        </div>
        <h2 class="careers-heading mt-4">Great Benefits</h2>
        <p>
          Spruce employees enjoy competitive salaries, excellent health
          benefits and more.
        </p>
      </div>
      <div class="col-sm-12 col-lg-4 ps-sm-2 ps-lg-4 details-card2">
        <div class="text-center">
          <img src="/assets/user.png" alt="user image" />
        </div>
        <h2 class="careers-heading mt-4">Strong Culture</h2>
        <p>
          We believe a supportive, collaborative environment and a purpose
          driven diverse team produce better results.
        </p>
      </div>
      <div class="col-sm-12 col-lg-4 ps-sm-2 ps-lg-4 details-card3">
        <div class="text-center">
          <img src="/assets/thum.png" alt="career image" />
        </div>
        <h2 class="careers-heading mt-4">Career Growth</h2>
        <p>
          We invest in employees' career development and offer opportunities
          to take on new roles.
        </p>
      </div>
    </div>
  </div>
</div>
<div class="tremendous-section pt-sm-0 pt-lg-5 pb-5">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 col-lg-6">
        <div class="tremendous-heading">
          <h1 class="careers-heading">A Tremendous Opportunity</h1>
        </div>
        <hr />
        <div>
          <p>
            Spruce is at the forefront of the clean energy economy, which
            employs more than 3 million people, according to a recent
            Advanced Energy Economy report. Residential solar and energy
            efficiency are among the fastest-growing industries in the U.S.
            – a 2017 report by The Solar Foundation found that solar jobs
            grew 17 times faster than the overall economy.
          </p>
          <p>
            We've raised more than $2.5B to help homeowners save money and
            improve their homes, communities, and the planet, and serve over
            80,000 homeowners.
          </p>
          <p>
            Spruce management and staff are purpose driven, results
            oriented, and collaborative in all we do. If these values
            resonate with you, see if any of our job openings are a match
            for your career goals, in our primary offices of Houston, Denver
            or the Bay Area.
          </p>
        </div>
      </div>
      <div class="col-sm-12 col-lg-6 p-4 text-center">
        <div>
          <h1 class="our-team-heading">Join Our Team Today</h1>
        </div>
        <div class="tremendous-view-button mt-5">
          <a href="https://sprucepowe1stg.wpengine.com/job-openings/">View Openings</a>
        </div>
        <div class="California-button">
          <a href="https://sprucepower.wpengine.com/wp-content/uploads/2022/10/CCPA-Notice-Employees-and-Applicants-008.pdf"
            target="_blank" rel="noopener">California Applicants Only</a>
        </div>
      </div>
    </div>
  </div>
</div>


<app-footer></app-footer>
